import React from "react";
import { navigate  } from 'gatsby'
import BlockContent from '../../components/block-content'
import { buildImageObj, cn } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import MdHeart from 'react-ionicons/lib/MdHeart'
import IosPinOutline from 'react-ionicons/lib/IosPinOutline'
import IosPhonePortrait from 'react-ionicons/lib/IosPhonePortrait'
import MdContacts from 'react-ionicons/lib/MdContacts'
import IosMail from 'react-ionicons/lib/IosMail'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardSubtitle,
	Form,
  Label,
  Input,
  InputGroup,
  FormGroup,
  Container,
  Row,
	Col,
	Badge,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

class ContactUsForm extends React.Component {
  state={}
  onFocus = (event, field) => {
		this.setState({[`${field}Focus`]: true});
  }
  
  onBlur = (event, field) => {
		this.setState({[`${field}Focus`]: false});
	}


  render() {
  const { pageBranding, _rawBody, mainImage} = this.props.page;
  const { hideBrand, companyInfo} = this.props;

    const fields = [
      {
        props: {
          type: 'text',
          autoComplete: 'off',
          ariaLabel: 'Your First Name ',
          name: 'firstname',
					required: true
        },
				icon: <MdContacts fontSize={'18px'} />, 
				classLabel: 'firstname',
				colWidth: 6
      },
      {
        props: {
          type: 'text',
          autoComplete: 'off',
          ariaLabel: 'Your Last Name ',
          name: 'lastname',
					required: true
        },
				icon: <MdContacts fontSize={'18px'} />, 
				classLabel: 'lastname',
				colWidth: 6
      },
      {
        props: {
          type: 'email',
          autoComplete: 'email',
          tabIndex: '0',
          ariaLabel: 'Your Email Address',
          name: '_replyto',
					required: true
        },
				icon: <IosMail fontSize={'18px'} />, 
				classLabel: 'email',
				colWidth: 12
      },
      {
        props: {
          type: 'textarea',
          autoComplete: 'off',
          tabIndex: '0',
          ariaLabel: 'Your Message',
					name: 'message',
					rows:"6",
          required: true
        },
				classLabel: 'message',
				colWidth: 12
      }
    ]
    return (
      <>
				<div
					className="section-image"
					style={{
						backgroundColor: '#1e1e24',
						backgroundImage: `url(${mainImage && mainImage.asset ? imageUrlFor(buildImageObj(mainImage)).width(1600).url() : null})`,
						paddingTop: 40,
						paddingBottom: 80,
					}}
				>
				<Container>
					<Row>
						<Col md="5" style={{padding: "10px 20px"}}>
							<Row>
								<Col sm={"12"}>
									<BlockContent blocks={_rawBody || []} />
								</Col>
							</Row>
							<Row>
								<Col sm={"12"}>
									<div className="info info-horizontal">
										<div className="icon icon-primary">
											<IosPinOutline fontSize={'36px'} />
										</div>
										<div className="description">
											<p className="description">
												<h4 className="info-title" style={{margin: 0}}>
													Made in {companyInfo.city && (<span>{companyInfo.city}</span>)} with&nbsp;&nbsp;<span className={"icon icon-danger"}><MdHeart beat={true}/></span>
												</h4>
												{companyInfo.address1 && (<span>{companyInfo.address1}<br/></span>)}
												{companyInfo.address1 && (<br/>)}
												{companyInfo.address2 && (<span>{companyInfo.address2}<br/></span>)}
												{companyInfo.address2 && (<br/>)}
												{companyInfo.city && (<span>{companyInfo.city}&nbsp;</span>)}
												{companyInfo.state && (<span>{companyInfo.state},&nbsp;</span>)}
												{companyInfo.zipCode && (<span>{companyInfo.zipCode}</span>)}
												{companyInfo.city && (<br/>)}
												{companyInfo.country && (<span>{companyInfo.country}</span>)}
											</p>
										</div>
									</div>
									<div className="info info-horizontal">
										<div className="icon icon-info">
											<IosPhonePortrait fontSize={'36px'} />
										</div>
										<div className="description">
											<p className="description">
												<h4 className="info-title" style={{margin: 0}}>Reach Us Here</h4>
												{companyInfo.email && (<span>{companyInfo.email}<br/></span>)}
												{companyInfo.phoneNumber && (<span>{companyInfo.phoneNumber}<br/></span>)}
												{companyInfo.miscInfo && (<span>{companyInfo.miscInfo}</span>)}
											</p>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
						<Col className="ml-auto mr-auto" md="5">
							<Card className="card-contact card-raised">
								<Form 
										role="form"
										className="form"
										action="https://formspree.io/xwdrvopm"
										method = "POST"
									>
									<CardHeader className="text-center">
										{ 
											pageBranding && !hideBrand && (
												<div className={'branding no-border-radius'} style={{margin: 'auto', maxWidth: 100}}>
													<img
														src = {
																imageUrlFor(buildImageObj(pageBranding))
																.width(120)
															.url()}
													/>
												</div>
											)
										}
									<CardTitle tag="h4">Contact Us:</CardTitle>
									</CardHeader>
									<CardBody>
										<Row>
											<Col md={fields[0].colWidth}>
												<FormGroup>
													<label>{fields[0].props.ariaLabel}</label>
													<InputGroup
														className={`${this.state[`${fields[0].classLabel}Focus`] ? 'input-group-focus' : ''}`}
													>
													{fields[0].icon && (
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<div className="icon">
																	{fields[0].icon}
																</div>
															</InputGroupText>
														</InputGroupAddon>
													)}
														<Input
															placeholder={fields[0].props.ariaLabel}
															type={fields[0].props.type}
															onFocus={e => (this.onFocus(e, fields[0].classLabel))}
															onBlur={e => (this.onBlur(e, fields[0].classLabel)) }
															{ ...fields[0].props}
														/>
													</InputGroup>
												</FormGroup>
											</Col>
											<Col md={fields[1].colWidth}>
												<FormGroup>
													<label>{fields[1].props.ariaLabel}</label>
													<InputGroup
														className={`${this.state[`${fields[1].classLabel}Focus`] ? 'input-group-focus' : ''}`}
													>
													{fields[1].icon && (
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<div className="icon">
																	{fields[1].icon}
																</div>
															</InputGroupText>
														</InputGroupAddon>
													)}
														<Input
															placeholder={fields[1].props.ariaLabel}
															type={fields[1].props.type}
															onFocus={e => (this.onFocus(e, fields[1].classLabel))}
															onBlur={e => (this.onBlur(e, fields[1].classLabel)) }
															{ ...fields[1].props}
														/>
													</InputGroup>
												</FormGroup>
											</Col>
											<Col md={fields[2].colWidth}>
												<FormGroup>
													<label>{fields[2].props.ariaLabel}</label>
													<InputGroup
														className={`${this.state[`${fields[2].classLabel}Focus`] ? 'input-group-focus' : ''}`}
													>
													{fields[2].icon && (
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<div className="icon">
																	{fields[2].icon}
																</div>
															</InputGroupText>
														</InputGroupAddon>
													)}
														<Input
															placeholder={fields[2].props.ariaLabel}
															type={fields[2].props.type}
															onFocus={e => (this.onFocus(e, fields[2].classLabel))}
															onBlur={e => (this.onBlur(e, fields[2].classLabel)) }
															{ ...fields[2].props}
														/>
													</InputGroup>
												</FormGroup>
											</Col>
											<Col md={fields[3].colWidth}>
												<FormGroup>
													<label>{fields[3].props.ariaLabel}</label>
													<InputGroup
														className={`${this.state[`${fields[3].classLabel}Focus`] ? 'input-group-focus' : ''}`}
													>
													{fields[3].icon && (
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<div className="icon">
																	{fields[3].icon}
																</div>
															</InputGroupText>
														</InputGroupAddon>
													)}
														<Input
															placeholder={fields[3].props.ariaLabel}
															type={fields[3].props.type}
															onFocus={e => (this.onFocus(e, fields[3].classLabel))}
															onBlur={e => (this.onBlur(e, fields[3].classLabel)) }
															{ ...fields[3].props}
														/>
													</InputGroup>
												</FormGroup>
											</Col>
										</Row>
										</CardBody>
										<CardFooter>
											<Row>
												<Col sm={4} className={'ml-auto mr-auto text-center'}>
													<Button type="submit" className={'btn-neutral btn-simple btn'} size="lg">
															Submit
													</Button>
												</Col>
											</Row>
										</CardFooter>
								</Form>
							</Card>
						</Col>
					</Row>
				</Container>
				</div>
      </>
    );
  }
}

export default ContactUsForm;
