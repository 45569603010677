import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/container/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import VideoBanner from '../components/video-banner/video-banner'
import ContactUsForm from '../components/contact-us-form/contact-us-form'

import { responsiveTitle1 } from '../components/typography.module.css'

export const query = graphql`
  query ContactPageQuery {
		companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
			name
			phoneNumber
			email
			miscInfo
      address1
      address2
      zipCode
			city
			state
			country
			socialMedia
    }

    page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      title
      _rawBody
			_rawSubtitle
      useVideo
      pageBranding {
        asset {
          _id
        }
      }
      mainVideo {
        embed {
          _type
          asset {
            url
          }
        }
      }
      mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = (typeof data === "object" && Object.keys(data).length > 0 ? data.page : {}); 
  const companyInfo = (typeof data === "object" && Object.keys(data).length > 0 ? data.companyInfo : {}); 

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  return (
    <Layout
			transparent={page ? true : false}
		>
      <SEO title={page.title} />
			<VideoBanner hideOverlay hideSubtitle { ...page}/>
      <div>
          <ContactUsForm page={page} companyInfo={companyInfo}/>
      </div>
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default ContactPage
